import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/authentication/login',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: 'authentication/register',
        method: 'post',
        data,
    });
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/api/v1/auth/logout',
        method: 'post',
        data,
    })
}
export async function apiActivate(data) {
    return ApiService.fetchData({
        url: `authentication/validateCode?email=${data.email}&code=${data.token}`,
        method: 'post',
        data,
    })
}
export async function apiRequestCode(data) {
    return ApiService.fetchData({
        url: `authentication/requestCode?email=${data.email}`,
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: `authentication/forgetPassword?email=${data.email}`,
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/api/v1/reset-password',
        method: 'post',
        data,
    })
}
export async function apiChangePassword(data) {
    return ApiService.fetchData({
        url: '/user/password/update',
        method: 'put',
        data,
    })
}

export async function apiVerify2fa(data) {
    return ApiService.fetchData({
        url: '/gateway/api/v1/authenticator/verify',
        method: 'post',
        data,
    })
}
